import './hbspt-form.scss';
import { getOffset, tryParseJSON } from './../../utilities/js/helper';

class HbsptForm {
    constructor ($element, options) {
        // Default options
        const defaults = {
            filledClassname: 'is--filled',
            focusedClassname: 'is--focused',
            errorClassname: 'has--error',
            submitBtnSelector: '[type="submit"]',
            referenceAttr: 'data-hubspot-reference',
            initAttr: 'data-hubspot',
            formIdsValue: 'form-ids'
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$form = $element;
        this.$formAnchor = null;
        this.$$formFields = null;
        this.$submitButton = null;
        this.$jsonData = document.querySelector(`[${this.settings.initAttr}="${this.settings.formIdsValue}"]`);

        // States and options
        this.formReference = this.$form.getAttribute(this.settings.referenceAttr);
        this.formId = `${this.formReference}-${this.settings.index}`;
        this.countryCode = Weglot.getCurrentLang(); // eslint-disable-line
        this.hbsptId = null;
    }

    initialize () {
        this.$form.id = this.formId;
        this.formId = `#${this.formId}`;

        if (this.$jsonData && Object.keys(window.hbsptIds).length === 0) {
            window.hbsptIds = tryParseJSON(this.$jsonData.textContent);
        }

        this.getMatchingId();

        if (this.hbsptId) {
            this.initializeForm();
        } else {
            this.$form.remove();
        }
    }

    getMatchingId () {
        const matchingKey = `hubspot_formid_${this.formReference}_${this.countryCode}`;
        const fallbackKey = `hubspot_formid_${this.formReference}`;
        this.hbsptId = window.hbsptIds?.[matchingKey] || window.hbsptIds?.[fallbackKey];
    }

    initializeForm () {
        const hbsptscript = document.createElement('script');
        hbsptscript.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(hbsptscript);
        hbsptscript.onload = () => {
            hbspt.forms.create({  // eslint-disable-line
                region: 'eu1',
                portalId: '26635911',
                formId: this.hbsptId,
                cssRequired: '',
                cssClass: '',
                locale: 'en',
                target: this.formId,
                onFormReady: ($form) => {
                    this.$formAnchor = document.getElementById('hubspot-form-' + this.formReference);
                    this.$$formFields = $form.querySelectorAll('select, input, textarea');
                    this.$submitButton = $form.querySelector(this.settings.submitBtnSelector);

                    const $loading = $form.querySelector('.loading');

                    if ($loading) {
                        $loading.parentNode.removeChild($loading);
                    }

                    this.changeSubmitButton();
                    this.setEvents();
                },
                onFormSubmitted: ($form) => {
                    const formSubmitEvent = new CustomEvent('form.submitted', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: $form
                        }
                    });

                    window.dispatchEvent(formSubmitEvent);
                }
            });
        };
    }

    getCountryCode () {
        const urlPath = window.location.pathname;
        const pathSegments = urlPath.split('/');
        return pathSegments[1];
    }

    changeSubmitButton () {
        const submitText = this.$submitButton.value;
        const $newButton = document.createElement('button');
        $newButton.setAttribute('type', 'submit');
        $newButton.classList.add('button');
        $newButton.innerText = submitText;

        this.$submitButton.replaceWith($newButton);
    }

    setEvents () {
        this.$form.addEventListener('change', (e) => {
            const $element = e.target;
            this.checkField($element);
        });

        window.addEventListener('form.submitted', (e) => {
            const eventDetails = e.detail;
            const $form = eventDetails.$context;

            if ($form) {
                const anchorDimensions = getOffset(this.$formAnchor);
                let anchorDimensionsTop = anchorDimensions.top;

                const $header = document.querySelector('[data-siteheader="root"]');
                if ($header) {
                    const headerHeight = $header.offsetHeight;
                    anchorDimensionsTop = anchorDimensionsTop - headerHeight;
                }

                window.scrollTo({
                    top: anchorDimensionsTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });

        this.$$formFields.forEach($field => {
            let fieldTimeout = null;

            $field.addEventListener('blur', () => {
                clearTimeout(fieldTimeout);
                fieldTimeout = window.setTimeout(() => {
                    this.checkField($field);
                }, 100);
            });
        });
    }

    checkField ($field) {
        let formType = $field.getAttribute('type');

        if (formType === null) {
            formType = $field.tagName.toLowerCase();
        }

        switch (formType) {
        case 'select': {
            let filled = true;
            const $element = $field.closest('.hs-fieldtype-select');

            if ($field.value === '') {
                filled = false;
            }

            if ($field.value === 0 || $field.value === '0') {
                const $emptyOption = $field.querySelector('option[value="0"]');
                if ($emptyOption.innerText === '') {
                    filled = false;
                }
            }

            if (filled === false) {
                $element.classList.remove(this.settings.filledClassname);
            } else {
                $element.classList.add(this.settings.filledClassname);
            }

            if ($field.classList.contains('error')) {
                $element.classList.add(this.settings.errorClassname);
            } else {
                $element.classList.remove(this.settings.errorClassname);
            }

            break;
        }
        case 'radio': {
            break;
        }
        case 'checkbox': {
            break;
        }
        case 'submit': {
            break;
        }
        default: {
            const $element = $field.closest('.hs-form-field');

            if ($field.value !== '') {
                $element.classList.add(this.settings.filledClassname);
            } else {
                $element.classList.remove(this.settings.filledClassname);
            }

            if ($field.classList.contains('error')) {
                $element.classList.add(this.settings.errorClassname);
            } else {
                $element.classList.remove(this.settings.errorClassname);
            }
        }
        }
    }
}

export { HbsptForm };

// Weglot.on('initialized', () => { // eslint-disable-line
//     const eventDetails = e.detail;
//     const $form = eventDetails.$context;
//     const formReference = eventDetails.formReference;

//     if ($form && formReference) {
//         const hbsptForm = new HbsptForm($form, formReference);
//         hbsptForm.initialize();
//     }
// });

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        window.addEventListener('weglot.loaded', () => {
            const $$roots = $context.querySelectorAll('[data-hubspot="root"]');
            $$roots.forEach(($root, index) => {
                const $rootAPI = new HbsptForm($root, { index });
                $rootAPI.initialize();
            });
        });
    }
});
